<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t("Faqs") }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white; padding-top: 36px; padding-bottom: 150px"
    >
      <div class="container">
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1" v-for="(item, k) of list" :key="item.id">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle="'accordion-' + item.id"
                variant="info"
                style="background-color: #8b4513;text-align: left;"
                >{{ k + 1 }}. {{ $getByLang(item.name) }}</b-button
              >
            </b-card-header>
            <b-collapse
              :id="'accordion-' + item.id"
              :visible="k == 0"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>{{ $getByLang(item.body) }}</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },

  created() {
    this.$http.get(`faqs`).then(
      (res) => {
        this.list = res.data;
      },
      (err) => {
        console.log(err);
      }
    );
  },
};
</script>

<style></style>
